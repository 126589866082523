import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import API from './components/Global_API'

const styles = {
    heading: {
        paddingTop: 12,
        paddingBottom: 12
    },
    title: {
        fontSize: 21,
        marginTop: '0 !important'
    },
    input: {
        height: 50,
        fontSize: 18,
    },
    button: {
        padding: 10,
        fontSize: 18,
        color: '#fff',
        backgroundColor: 'rgb(20, 108, 240)',
        borderRadius: 5,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    disabled_button: {
        padding: 10,
        fontSize: 18,
        color: '#fff',
        backgroundColor: 'rgb(20, 108, 240)',
        borderRadius: 5,
        fontWeight: 'bold',
        cursor: 'no-drop'
    }
}

class ForgotScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            api_response_message: ''
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow() {
        this.setState({
            show: true,
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });

        let targetId = e.target.id;
        let value = e.target.value;
        let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_email')[0].innerHTML = 'Email is required.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 5 || value.trim().length > 50) {
            document.getElementsByClassName('error_email')[0].innerHTML = 'Email must be between 5 to 50 character.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (!value.trim().match(pattern)) {
            e.target.classList.add('error');
            document.getElementsByClassName('error_email')[0].innerHTML = 'Please Enter valid Email ID.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_email')[0].innerHTML = "";
            document.getElementsByClassName('error_email')[0].style.display = 'none';
        }
    }


    handleSubmit = (e) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        e.preventDefault();
        if (this.state.email.trim().length < 5) {
            document.getElementById("email").focus();
        } else if (!this.state.email.trim().match(pattern)) {
            document.getElementById("email").focus();
        } else {

            
            let Forgot = JSON.stringify(
                {
                    email_id: this.state.email,
                });

            fetch(API +'forgot_password.php',
                {
                    method: 'POST',
                    body: Forgot
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    this.setState({ show: true });
                    
                    if (responseJsonFromServer.result === 'success') {
                        this.setState({ api_response_message: responseJsonFromServer.msg });
                    } else {
                        this.setState({ api_response_message: responseJsonFromServer.msg });
                        
                    }
                }).catch((error) => {
                    console.log(error.message);
                });
        }
    }
    render() {
        return (
            <div className="image-background">
                <div className="container" style={{ paddingTop: '10%' }}>
                    <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 30 }}>
                        <img src={require('../assets/pta-logo2.png')} alt="logo" style={{ width: 80, height: 80 }} />
                    </div>
                    <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0 }}>

                        <div className="row col-lg-6 col-xl-6 col-md-8 col-sm-10 divcontainer">
                            <div className="col-10 center">
                                <div style={styles.heading}>
                                    <h4 style={styles.title}>Forgot your password?</h4>
                                    <p>Enter your email address and we will send you a link to reset your password.</p>
                                </div>
                                <form>
                                    <div>
                                        <input style={styles.input} type="email" class="form-control" id="email" value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email" />
                                        <label className="input-error error_email text-left"></label>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        {/* <button type="button" onClick={this.handleSubmit} className="btn btn-block" style={styles.button}><text>Reset my password</text></button> */}
                                        <button onClick={this.handleSubmit} disabled={this.state.email === '' ? true : false} className="btn btn-block" style={this.state.email === '' ? styles.disabled_button : styles.button}><text>Reset my password</text></button>
                                    </div>
                                    <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
                                        <Modal.Body>{this.state.api_response_message}</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" block onClick={this.handleClose}>OK</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </form>
                            </div>
                            {/* <div id="login"></div> */}
                        </div>

                    </div>
                    <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                        <text style={{ textAlign: 'center', color: '#fff', fontWeight: 'bold' }} >Remember your password? <a href="/" style={{ color: '#fff' }}>Sign in here.</a></text>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotScreen;
