import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Table, Accordion, Card, Modal } from 'react-bootstrap';
import API from './components/Global_API'
import { Icon } from 'react-icons-kit'
import { cross } from 'react-icons-kit/icomoon/'
import { check } from 'react-icons-kit/fa'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navigation from './components/Navigation';
import "../CSS/LearnerGroup.css"
import moment from 'moment';

const styles = {
    label: {
        fontWeight: '600',
        fontSize: 18
    },
    clearFilterIcon: {
        padding: 5,
        color: 'rgb(138, 141, 150)',
    },
    inputField: {
        height: 40
    }
}
export default class Learner_Group extends React.Component {
    constructor() {
        super();
        this.state = {
            start_date: '',
            end_date: '',
            learner_group_data: [],
            all_learner_group_data: [],
            userId: '',
            course_name: '',
            course_data: [],
            filter_by_course: [],
            search_learner: '',
            filter_by_learner: [],
            filter_data: [],
            search_assessor: '',
            filter_by_assessor: [],
            filter_by_start_date: [],
            filter_by_end_date: [],
            action_msg_popup: false,
            action_msg: '',
            isLoading: true
        }
        if (localStorage.getItem('IqaloginUser')) {
            this.state.userId = JSON.parse(localStorage.getItem('IqaloginUser')).userId;
        }
    }


    onChangeGroup = e => {
        this.setState({ search_assessor: e.target.value })

        let assessor = this.state.all_learner_group_data;
        if (e.target.value.trim().learner == 0) {
            assessor = this.state.all_learner_group_data;
            this.setState({ filter_by_assessor: this.state.all_learner_group_data });
        } else {
            let iqa = this.state.all_learner_group_data
            let filterdIqa = iqa.filter((item) => {
                return item.assessor_name.toLowerCase().includes(e.target.value.toLowerCase());
            });
            this.setState({ filter_by_assessor: filterdIqa });
            assessor = filterdIqa;
        }

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.filter_data.intersection(this.state.filter_by_learner.intersection(assessor))
        this.setState({ learner_group_data: filter_data })
    };

    onChangeLearner = e => {
        this.setState({ search_learner: e.target.value })
        let learner = this.state.all_learner_group_data;
        if (e.target.value.trim().learner == 0) {
            learner = this.state.all_learner_group_data;
            this.setState({ filter_by_learner: this.state.all_learner_group_data });
        } else {
            let iqa = this.state.all_learner_group_data
            let filterdIqa = iqa.filter((item) => {
                return (item.middle_name ? item.first_name.toLowerCase() + ' ' + item.middle_name.toLowerCase() + ' ' + item.last_name.toLowerCase() : item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase());
            });
            this.setState({ filter_by_learner: filterdIqa });
            learner = filterdIqa;
        }

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.filter_data.intersection(this.state.filter_by_assessor.intersection(learner))
        this.setState({ learner_group_data: filter_data })
    };


    get_learner_group = () => {
        let learner_group = JSON.stringify(
            {
                iqa_id: this.state.userId,
            });


        fetch(API + 'get_all_learner.php',
            {
                method: 'POST',
                body: learner_group
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                this.setState({ isLoading: false })
                if (responseJsonFromServer.result === 'success') {
                    this.setState({
                        learner_group_data: responseJsonFromServer.search_data,
                        all_learner_group_data: responseJsonFromServer.search_data,
                        filter_by_course: responseJsonFromServer.search_data,
                        filter_data: responseJsonFromServer.search_data,
                        filter_by_learner: responseJsonFromServer.search_data,
                        filter_by_assessor: responseJsonFromServer.search_data,
                        filter_by_start_date: responseJsonFromServer.search_data,
                        filter_by_end_date: responseJsonFromServer.search_data
                    })
                }
            }).catch((error) => {
                console.log(error.message);
            });

    }

    componentDidMount() {
        this.get_learner_group();

        fetch(API + 'display_course.php').then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result === 'success') {
                this.setState({ course_data: responseJsonFromServer.course_data })
            }
        }).catch((error) => {
            console.log(error.message);
        });
    }

    handleLearner(item) {

        let learner_group = JSON.stringify(
            {
                iqa_id: this.state.userId,
                learner_id: item.id,
                course_id: item.course_id
            });

        fetch(API + 'check_action.php',
            {
                method: 'POST',
                body: learner_group
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result === 'success') {
                    this.props.history.push("/Sampling_report", { learner: item })
                } else {
                    this.setState({ action_msg_popup: true, action_msg: responseJsonFromServer.msg })

                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    handleCourseName = (e) => {
        this.setState({ course_name: e.target.value })

        let learner = this.state.all_learner_group_data

        if (e.target.value === '') {
            this.setState({ filter_by_course: this.state.all_learner_group_data })
        } else {
            let filter_course = learner.filter((item) => {
                return item.course_id == e.target.value;
            });

            this.setState({ filter_by_course: filter_course })
        }
    }

    handleStartDateFilter = (date) => {
        this.setState({ start_date: date })
        let filter_start_date = [];
        if (date) {
            let olddate = date.toLocaleDateString("en-US")
            let newdate = moment(olddate).format('M/D/YYYY')
            filter_start_date = this.state.all_learner_group_data.filter((item) => {
                let startDate = item.submission_date.split('/');
                let day = startDate[0];
                let month = startDate[1];
                let year = startDate[2];
                let submission_date = month + '/' + day + '/' + year
                console.log("start submission_date",new Date(submission_date));
                console.log("start newdate",new Date(newdate));
                // if (submission_date >= newdate) {
                if (new Date(submission_date) >= new Date(newdate)) {
                    return item
                }
            })
        } else {
            filter_start_date = this.state.all_learner_group_data
        }
        this.setState({ filter_by_start_date: filter_start_date })


    }

    handleEndDateFilter = (date) => {
        this.setState({ end_date: date })
        let filter_end_date = [];
        if (date) {
            let olddate = date.toLocaleDateString("en-US")
            let newdate = moment(olddate).format('M/D/YYYY')
            filter_end_date = this.state.all_learner_group_data.filter((item) => {
                let endDate = item.submission_date.split('/');
                let day = endDate[0];
                let month = endDate[1];
                let year = endDate[2];
                let submission_date = month + '/' + day + '/' + year
                console.log("end submission_date",submission_date);
                console.log("end newdate",newdate);
                if (new Date(newdate) >= new Date(submission_date)) {
                    console.log("sorted data", item);
                    return item
                }
            })
        } else {
            filter_end_date = this.state.all_learner_group_data
        }
        this.setState({ filter_by_end_date: filter_end_date })

    }

    handleApplyFilter = () => {

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.filter_by_course.intersection(this.state.filter_by_learner.intersection(this.state.filter_by_assessor.intersection(this.state.filter_by_start_date.intersection(this.state.filter_by_end_date))))
        this.setState({ learner_group_data: filter_data, filter_data: filter_data })

    }

    handleClearFilter = () => {
        this.setState({
            learner_group_data: this.state.all_learner_group_data,
            filter_by_course: this.state.all_learner_group_data,
            filter_by_learner: this.state.all_learner_group_data,
            filter_by_assessor: this.state.all_learner_group_data,
            filter_by_start_date: this.state.all_learner_group_data,
            filter_by_end_date: this.state.all_learner_group_data,
            filter_data: this.state.all_learner_group_data,
            course_name: '',
            start_date: '',
            end_date: '',
            search_learner: '',
            search_assessor: '',
        })
    }

    handleClearAssessor = () => {
        this.setState({
            filter_by_assessor: this.state.all_learner_group_data,
            search_assessor: '',
        })

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.filter_data.intersection(this.state.filter_by_learner.intersection(this.state.all_learner_group_data))
        this.setState({ learner_group_data: filter_data })
    }

    handleClearLearner = () => {
        this.setState({
            filter_by_learner: this.state.all_learner_group_data,
            search_learner: '',
        })

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.filter_data.intersection(this.state.filter_by_assessor.intersection(this.state.all_learner_group_data))
        this.setState({ learner_group_data: filter_data })
    }

    render() {
        return (
            <div style={{ minHeight: '100vh', overflowX: 'hidden', backgroundColor: '#fff' }}>
                <Navigation />
                <div style={{ backgroundColor: '#fff' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 80, paddingRight: 20, paddingBottom: 20 }}>
                        <div>
                            <div className="row" style={{ marginTop: -10, marginBottom: -10 }}>
                                <Accordion style={{ width: '100%' }} defaultActiveKey="0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 block-v-sm">
                                            <label style={{ fontSize: 25, paddingLeft: 30, fontWeight: '600' }}>Dashboard</label>
                                        </div>
                                        <div className="col-md-4 col-sm-4 text-center block-v-sm" style={{ paddingTop: 5, paddingRight: 30 }}>
                                            <Accordion.Toggle as={Button} style={{ backgroundColor: '#ebeced', fontSize: 18 }} variant="default" eventKey="0">
                                                Filter by <span style={{ fontWeight: '700' }}>Learner </span>
                                            </Accordion.Toggle>
                                        </div>
                                    </div>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body >
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <div className="col-12">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <label style={styles.label}>Courses</label>
                                                            <select value={this.state.course_name} className="form-control" onChange={this.handleCourseName}>
                                                                <option value="">Any</option>
                                                                {this.state.course_data.map(item =>
                                                                    <option key={item.course_id} value={item.course_id}>{item.course_name}</option>
                                                                )}

                                                            </select>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <label style={styles.label}>Assessor</label>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="col-11 p-0" >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search for Assessor"
                                                                        className="form-control"
                                                                        onChange={this.onChangeGroup}
                                                                        value={this.state.search_assessor}
                                                                        style={styles.inputField}
                                                                    />
                                                                    {/* {GroupsListComponent} */}
                                                                </div>
                                                                <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 3, cursor: 'pointer', height: 40 }} onClick={this.handleClearSearch}>
                                                                    <Icon icon={cross} size="20" onClick={this.handleClearAssessor} style={styles.clearFilterIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pt-3">
                                                            <label style={styles.label}>Submission date</label>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        placeholderText="Start Date"
                                                                        maxDate={this.state.end_date || new Date()}
                                                                        selected={this.state.start_date}
                                                                        onChange={this.handleStartDateFilter}
                                                                        showTimeSelect={false}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        locale={false}
                                                                        showTimeInput={false}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        placeholderText="End Date"
                                                                        minDate={this.state.start_date}
                                                                        maxDate={new Date()}
                                                                        selected={this.state.end_date}
                                                                        onChange={this.handleEndDateFilter}
                                                                        showTimeSelect={false}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        locale={false}
                                                                        showTimeInput={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pt-3">
                                                            <label style={styles.label}>Learner</label>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="col-11 p-0" >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search for Learner"
                                                                        className="form-control"
                                                                        onChange={this.onChangeLearner}
                                                                        value={this.state.search_learner}
                                                                        style={styles.inputField}
                                                                    />
                                                                </div>
                                                                <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 3, cursor: 'pointer', height: 40 }} onClick={this.handleClearSearch}>
                                                                    <Icon icon={cross} size="20" onClick={this.handleClearLearner} style={styles.clearFilterIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pt-3"></div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pt-3" style={{ float: 'right' }}>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <Button block onClick={this.handleApplyFilter}>Apply filter</Button>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Button block onClick={this.handleClearFilter}>Clear filter</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="body-container" style={{ padding: 40, }}>
                        <h5>Welcome to the IQA dashboard</h5>
                        <p><span style={{ fontWeight: '600' }}>Total number of submissions</span> "{this.state.learner_group_data.length}"</p>
                        {this.state.isLoading ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 30 }}>
                                <img src={require('../assets/Loader.gif')} />
                            </div> :
                            this.state.learner_group_data.length == 0 ?
                                <div className="col-12 text-center">
                                    <h4>No user found</h4>
                                </div> : <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th width="150">Learner Name</th>
                                            <th width="100">Submission Date</th>
                                            <th width="100">resubmission Date</th>
                                            <th width="100">Course</th>
                                            <th width="100">Type</th>
                                            <th width="100">Assessor</th>
                                            <th className="text-center" width="100">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left" style={{ backgroundColor: '#fff' }}>
                                        {this.state.learner_group_data.map((item, i) => (
                                            <tr key={i} onClick={() => this.handleLearner(item)} style={{ cursor: 'pointer' }}>
                                                <td>{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</td>
                                                <td>{item.submission_date}</td>
                                                <td>{item.resubmission_date}</td>
                                                <td>{item.course_name}</td>
                                                <td>{item.learner_type}</td>
                                                <td>{item.assessor_name}</td>
                                                <td className="text-center">{item.action == "in progress" ? item.action : <Icon icon={check} size={25} style={{ color: '#fff', backgroundColor: item.action == 'true' ? 'green' : '#b1b1b1', borderRadius: 25, padding: 5 }} />}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>}

                        <Modal size="sm" style={{ marginTop: '20%' }} show={this.state.action_msg_popup} onHide={() => this.setState({ action_msg_popup: false })}>
                            {/* <Modal.Header style={{ padding: 10 }} closeButton></Modal.Header> */}
                            <Modal.Body >
                                {/* <div> */}
                                <h6>{this.state.action_msg}</h6>
                                {/* </div> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="mt-2" variant="primary" onClick={() => this.setState({ action_msg_popup: false })} style={styles.button} block>Ok</Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}